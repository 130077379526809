import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
    
    .coupons-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        .code {
            display: inline-block;
            padding: 16px 32px;
            margin: 16px auto;
            border-radius: 8px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.5px;
            background: #ffffff;
            color: rgba(21, 22, 27, 1);
            border-radius: 8px;
        }
        .code-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 12px;
            font-weight: 400;
            .code-info_item {
                color: rgba(21, 22, 27, 0.60);
                font-size: 12px;
                font-weight: 400;
                display: flex;
                align-items: center;
                line-height: 18px;
                &::before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: rgba(21, 22, 27, 0.60);
                    margin: 0 8px;
                }
            }
        }

        
    }

    
`;
