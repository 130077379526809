import React from 'react';
import { DialogStyle } from './style';
import cn from 'classnames';
import { formateTimeByTimeZone } from 'utils/datetime';
import { useIntl } from 'react-intl';
import { useCouponsDetail } from './hooks';
import { useCoupons } from 'hook/discount/use-coupons';
import { Dialog } from 'zent';
import NewButton from 'components/base/new-button';
import copy from 'copy-to-clipboard';
const CouponsModal = (props) => {
  const intl = useIntl();

  const { isOpened, itemName, currency, startAt, endAt, itemCode, result, itemUnique, onCancel, history } = props;
  const { couponDetail = {}, loading } = useCouponsDetail(itemCode, result);

  const { usePage, useCondition, useRange } = useCoupons({
    intl,
    currency,
    data: couponDetail,
  });
  const copyToUse = () => {
    copy(itemUnique);
    window.open(`${usePage}&noCouponsModal=true`);
  };
  return (
    <div>
      <DialogStyle />

      <Dialog
        className={cn('coupons-modal', props.className)}
        visible={isOpened}
        onClose={onCancel}
        maskClosable={false}
        footer={null}
        title={itemName}
      >
        <div className='sub-title'>{useCondition}</div>
        <div className='code skiptranslate'>{itemUnique}</div>
        <div className='code-info'>
          <div className='code-info_item'>
            {intl.formatMessage({
              id: '70382e433521489593a7c25b8e5548fa',
              defaultMessage: '有效期',
            }) + `：${formateTimeByTimeZone(startAt, 'YYYY.MM.DD')}-${formateTimeByTimeZone(endAt, 'YYYY.MM.DD')}`}
          </div>
          <div className='code-info_item'>{useRange}</div>
        </div>
        <NewButton className='btn' type='primary' size='large' onClick={copyToUse}>
          {intl.formatMessage({
            id: '230b020218a34dbfa2d0dba4f5031460',
            defaultMessage: '复制去使用',
          })}
        </NewButton>
      </Dialog>
    </div>
  );
};
export default CouponsModal;
