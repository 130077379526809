import { useState, useEffect } from 'react';
import couponsApi from 'api/coupons';
import { DrawResult } from 'const/activity';

export const useCouponsDetail = (activityId, result) => {
  const [couponDetail, setCouponDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const { kdtId } = {};
  //const { kdtId } = !__isServer ? window?.global_data : {};
  const getCouponDetail = async () => {
    if (activityId) {
      const params = {
        kdtId,
        activityId,
      };
      setLoading(true);
      const couponDetail = await couponsApi.getCouponDetailById(params);
      setCouponDetail(couponDetail);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!result || result === DrawResult.Success) {
      getCouponDetail();
    }
  }, [kdtId, activityId, result]);

  return {
    couponDetail,
    loading,
  };
};
