import React, { useEffect, useState } from 'react';
import { useThemeContext } from 'hook/global-theme';
import { Dialog, FormError, Input } from 'zent';
import Style, { DialogStyle } from './style';
import { useIntl } from 'react-intl';
import { useActivity } from './hooks';
import { useBindEmail } from 'hook/account/bindEmailHooks';
import { useDrawShare } from './hooks/use-draw-share';
import { ActivityStatus, DrawResultIntl, DrawResult } from 'const/activity';
import { diffRelativeDateTimeWithDay, formateTimeByTimeZone } from 'utils/datetime';
import Toast from 'cpn/toast';
import { getQueryString } from 'utils';
import { Icon } from 'components/base';
import Image from 'components/base/image';

import cn from 'classnames';
import Coupons from './components/coupons';
import CouponsModal from './components/couponsModal/index';
import NewButton from 'components/base/new-button';
import { ShareButtons } from 'components/page/share-buttons';
import { isNumber, isEmpty } from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';
import { buriedPoint } from 'utils/log-buried-point';

const defaultLogo = 'https://b.yzcdn.cn/path/to/cdn/dir/logo-default.png';
const LuckyDraw = (props) => {
  const { isMobile } = useThemeContext();
  const intl = useIntl();
  const { initialData: { kdtId, userInfo, shopLogoUrl } = {} } = props;

  const { customerNo, errorText, handleEmailChange, bindEmail } = useBindEmail({ intl });

  const { activityId, isEdit } = getQueryString(props.location.search);
  const [showShared, setShowShared] = useState(false);

  const {
    customerId,
    activitySetting = {}, // 活动设置
    activityTickets, // 抽奖次数
    sharedCount, // 已分享获得抽奖次数
    bulkActivityNameList = [], // 中奖名单
    activityItems = [], // 奖品
    startDraw, // 抽奖函数
    drawResult = {}, // 中奖结果
    selectIndex, // 跑马灯当前选中项
    getDrawActivitySetting, // 获取抽奖活动详细
    showDrawModal, // 是否显示中奖弹窗
    setShowDrawModal,
    showCouponsModal, // 是否显示中奖的优惠券弹窗
    setShowCouponsModal,
    showThanksModal, // 是否显示谢谢惠顾弹窗
    setShowThanksModal,
    showEmailModal, // 是否显示绑定邮箱弹窗
    setShowEmailModal,
    setIsBindEmailSuccess, // 是否已经绑定邮箱成功
    showRecordModal,
    setShowRecordModal,
    activityRecord,
    getDrawActivityRecords, // 抽奖记录
    login,
    getDrawActivityTickets,
    setIsStrictDrawing, // 是否直接进行抽奖
    isDrawing, // 是否正在抽奖
  } = useActivity({ activityId, isEdit, customerNo, kdtId, userInfo, initialData: props.initialData });

  const [couponsModalItem, setCouponsModalItem] = useState({});
  const { drawPage = {}, drawRule = {} } = activitySetting;
  const joinType = drawRule?.joinType;
  const ticketsType = drawRule?.ticketsRule?.type;
  const baseTickets = drawRule?.ticketsRule?.baseTickets;
  const shareTickets = drawRule?.ticketsRule?.shareTickets;
  const viewTickets = (isNumber(activityTickets) || isNumber(baseTickets)) ?
    (isNumber(activityTickets) ? activityTickets : baseTickets) : '?'

  const { shareLinkUrl, onTriggerShare } = useDrawShare({
    intl,
    activityId,
    customerId,
    customerNo,
    joinType,
    userInfo,
    onSharedFinishedCallBack: getDrawActivityTickets,
  });

  // 中奖名单动效相关处理
  // 高度=数据条数*36-18
  const scrollHeight = bulkActivityNameList.length * 36 - 18;
  const scrollMinHeight = 6 * 36 - 18; // 超过6条就滚动
  const scrollTime = scrollHeight >= scrollMinHeight ? (scrollHeight - scrollMinHeight) / 20 : 0;

  const drawResultIntlObj = DrawResultIntl[drawResult?.result || DrawResult.Success];
  // 开始时间倒计时
  const [timerObj, setTimerObj] = useState({});
  // 结束时间倒计时
  const [endTimerObj, setEndTimerObj] = useState({});

  const [drawRuleVisible, setDrawRuleVisible] = useState(false);
  const [showDrawCountNone, setShowDrawCountNone] = useState(false);

  const getWithZero = (relativeTime) => {
    const newTime = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(relativeTime).map((key) => {
      // 天不补充0
      if (key !== 'day') {
        newTime[key] = relativeTime?.[key] < 10 ? `0${relativeTime?.[key] ?? 0}` : `${relativeTime?.[key]}`;
      } else {
        newTime[key] = `${relativeTime?.[key]}`;
      }
    });
    return newTime;
  };

  useEffect(() => {
    if (activitySetting?.status === ActivityStatus.Expired) return;
    let interval: any = null;
    if (activitySetting.startAt && activitySetting.startAt > new Date().getTime()) { // 开始倒计时
      interval = setInterval(() => {
        const timerObjTemp = diffRelativeDateTimeWithDay(activitySetting.startAt) || {};
        setTimerObj(timerObjTemp);
        if (activitySetting.startAt > new Date().getTime()) {
          setTimerObj(timerObjTemp);
        } else {
          setTimerObj({});
          clearInterval(interval);
          getDrawActivitySetting();
        }
      }, 1000);
    } else if (activitySetting.endAt && activitySetting?.drawRule?.countDownRule?.countDownSwitch === 1) { // 结束倒计时
      const countDownDay = activitySetting?.drawRule?.countDownRule?.countDownDay;
      const endDay = diffRelativeDateTimeWithDay(activitySetting.endAt)?.day;
      if (endDay <= countDownDay) {
        interval = setInterval(() => {
          const timerObjTemp = diffRelativeDateTimeWithDay(activitySetting.endAt) || {};
          if (activitySetting.endAt > new Date().getTime()) {
            setEndTimerObj(timerObjTemp);
          } else {
            setEndTimerObj({});
            clearInterval(interval);
            getDrawActivitySetting();
          }
        }, 1000);
      }
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [activitySetting?.status, activitySetting.startAt, activitySetting?.drawRule?.countDownRule]);
  // console.log('是否拿到数据', activitySetting, shopLogoUrl, new Date().getTime());

  const getThanksModalBtn = () => {
    const canContinue = activityTickets !== undefined && activityTickets > 0;
    const cantToShare = !canContinue && sharedCount !== undefined && shareTickets - sharedCount > 0;
    const noCount = !canContinue && !cantToShare;
    return (
      <NewButton
        className='btn'
        type='primary'
        size='large'
        onClick={() => {
          setShowThanksModal(false);
          if (canContinue) {
            startDraw();
          }
          if (cantToShare) {
            setShowShared(true);
          }
        }}
      >
        {canContinue &&
          intl.formatMessage({
            id: 'fa42553df29247959410ac739e08778b',
            defaultMessage: '继续抽奖',
          })}
        {cantToShare &&
          intl.formatMessage({
            id: 'e07b375054774168aacd6373840bb952',
            defaultMessage: '分享赚次数',
          })}
        {noCount &&
          intl.formatMessage({
            id: 'common_confirm',
            defaultMessage: '确定',
          })}
      </NewButton>
    );
  };

  const viewDrawRecord = () => {
    // 未登陆跳转登陆页面
    if (!customerId) {
      login();
      // if (drawRule?.joinType === 'login') {
      //   // 登陆抽奖则让用户登陆
      //   login();
      // } else {
      //   // 订阅抽奖提示用户邮箱查看中奖;
      //   Toast.info(
      //     intl.formatMessage({
      //       id: '026cf1ed19fd4d3d8aaa0a68561299a2',
      //       description: '请前往邮箱查看中奖记录',
      //     }),
      //     800,
      //   );
      // }
    } else {
      getDrawActivityRecords();
    }
  };

  return (
    <Style>
      <DialogStyle />
      <div
        className='luck-activity'
        style={{
          backgroundColor: drawPage?.backType === 'color' && drawPage.colorValue,
          backgroundImage:
            drawPage?.backType === 'image' && !!drawPage?.imageValue ? `url(${drawPage?.imageValue})` : 'none',
        }}
      >
        <div className='main'>
          <div className='draw-content'>
            {/* <Image alt='background' className='coin-bg' src='https://b.yzcdn.cn/path/to/cdn/dir/coin-bg3.png' /> */}
            {drawPage?.showLogo ? (
              <Image
                alt='background'
                className='logo'
                src={shopLogoUrl || defaultLogo}
                style={{ objectFit: 'contain', objectPosition: 'center' }}
                onClick={() => {
                  window.open('/');
                }}
              />
            ) : null}

            <div
              id='title_app'
              className='title'
              style={{ fontSize: `${((isMobile ? 40 : 80) * drawPage.titleSize) / 100}px` }}
              dangerouslySetInnerHTML={{
                __html: drawPage?.title,
              }}
            />

            <div
              className='sub-title'
              style={{ fontSize: `${((isMobile ? 10 : 20) * drawPage.subTitleSize) / 100}px` }}
              dangerouslySetInnerHTML={{
                __html: drawPage?.subTitle,
              }}
            />
            <Image
              alt='background'
              className='main-bg'
              src='https://b.yzcdn.cn/path/to/cdn/dir/main-bg3.png'
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />
            <div className='row'>
              {
                !isEmpty(endTimerObj) &&
                <div className='draw-count-down-container'>
                  <div className='draw-count-down-title'>
                    {intl.formatMessage({ id: '362714173ac34d0fa3a32388b90c4ce5', defaultMessage: '距离结束' })}
                  </div>
                  <div className='draw-count-down'>
                    <div className='draw-count-down_item skiptranslate'>{endTimerObj.day}</div>
                    {intl.formatMessage({ id: '1621378f794d414a8a47b70d740d12eb', defaultMessage: '天' })}
                    <div className='draw-count-down_item skiptranslate'>{endTimerObj.hour}</div>:<div className='draw-count-down_item skiptranslate'>{endTimerObj.min}</div>:
                    <div className='draw-count-down_item skiptranslate'>{endTimerObj.second}</div>
                  </div>
                </div>
              }
              <div className='luck-draw-container'>
                <div className='luck-draw'>
                  <div className='diejia'>
                    <div className='diejia2'>
                      <div className='turntable'>
                        <div className='list'>
                          {activityItems?.map((item, index) => {
                            return (
                              <div
                                key={item.itemName}
                                className={cn(`item list-${index + 1}`, {
                                  active: index === selectIndex,
                                })}
                              >
                                <Image
                                  alt='background'
                                  className='img'
                                  src={item.itemImageUrl}
                                  style={{
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                  }}
                                />

                                <div className='name'>{item.itemName}</div>
                              </div>
                            );
                          })}
                        </div>
                        {(isEdit || activitySetting.status === ActivityStatus.Scheduled) && (
                          <div
                            className={cn('start-btn', 'skiptranslate', {
                              disabled: activityTickets === 0,
                            })}
                            onClick={() => {
                              if (isDrawing) {
                                return;
                              }
                              if (activityTickets === 0) {
                                setShowDrawCountNone(true);
                              }
                              startDraw();
                            }}
                          >
                            <div className='go'>GO</div>
                            <div className='arrow' />
                          </div>
                        )}
                        {activitySetting.status === ActivityStatus.Waiting && (
                          <div className='start-btn disabled'>
                            <span className='wait-start'>
                              {intl.formatMessage({
                                id: 'discount_waiting',
                                defaultMessage: '待开始',
                              })}
                            </span>
                            <span className='time skiptranslate'>
                              {intl.formatMessage(
                                {
                                  id: '7c213bbc24b74e5f8fb03f501dbadb18',
                                },
                                {
                                  day: timerObj.day || 0,
                                  hour: timerObj.hour || 0,
                                  min: timerObj.min || 0,
                                  second: timerObj.second || 0,
                                },
                              )}
                            </span>
                          </div>
                        )}
                        {activitySetting.status === ActivityStatus.Expired && (
                          <div className='start-btn disabled'>
                            <span className='expired'>
                              {intl.formatMessage({
                                id: 'discount_expired',
                                defaultMessage: '已结束',
                              })}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='draw-num'>
                        <span className='text'>
                          {intl.formatMessage({
                            id: '631a8d2ece044c6983019c2990da662f',
                            defaultMessage: '抽奖机会',
                          })}
                        </span>
                        <span className='num'>{viewTickets}</span>
                      </div>
                      <div className='record-and-share'>
                        <div
                          className='share-btn'
                          onClick={() => {
                            setShowShared(!showShared);
                            if (sharedCount !== undefined && shareTickets - sharedCount > 0) {
                              onTriggerShare();
                            }
                          }}
                        >
                          <Icon className='iconchoujiangfenxiang' name='iconchoujiangfenxiang' />
                          {intl.formatMessage({
                            id: 'share',
                            defaultMessage: '分享',
                          })}
                          {sharedCount !== undefined && shareTickets - sharedCount > 0 && (
                            <div className='share-btn-tip'>
                              {intl.formatMessage({
                                id: 'befa5bf540e1432a907ec5237496e5cd',
                                defaultMessage: '立得1次',
                              })}
                            </div>
                          )}
                        </div>
                        <div className='record-btn' onClick={viewDrawRecord}>
                          <Icon className='iconzhongjiangjilu' name='iconzhongjiangjilu' />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='share-popover' style={{ display: showShared ? 'block' : 'none' }}>
                    <div className='label'>
                      {intl.formatMessage({
                        id: '53d9f2259cba4c748dda395a5a18e716',
                        defaultMessage: '分享给朋友',
                      })}
                    </div>
                    <ShareButtons
                      // 生成海报的参数
                      // postInfo={{
                      //     goodsId,
                      //     activityId,
                      // }}
                      showWeChat
                      shareThirdUrl={`activity/lucky-draw?activityId=${activityId}`}
                      copyUrl={shareLinkUrl}
                      customClass='lunk-draw-share-btns'
                      socialShareData={{}}
                      handleBuriedPoint={onTriggerShare}
                    />
                  </div> */}
                </div>
              </div>
              <div className='section-container'>
                {drawPage?.showNameList && bulkActivityNameList.length > 0 ? (
                  <div className='section'>
                    <div className='section-title'>
                      <div className='icon' />
                      <span className='text'>
                        {' '}
                        {intl.formatMessage({
                          id: '41cfe1d3e3f343a9b37c35943211885f',
                          defaultMessage: '中奖名单',
                        })}
                      </span>
                      <div className='icon' />
                    </div>
                    <div className='container' style={{ height: `${scrollHeight}px` }}>
                      <div className='section-content' style={{ animation: `scroll ${scrollTime}s linear infinite` }}>
                        {bulkActivityNameList.map((item, index) => {
                          return (
                            <div className='item' key={item.customerName + index}>
                              <span className='name'>{item.customerName}</span>
                              <span className='draw-name'>{item.itemName}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className='section'>
                  <div className='section-title'>
                    <div className='icon' />
                    <span className='text'>
                      {intl.formatMessage({
                        id: 'caeba54e1d9043ab972684a2b1aa29ec',
                        defaultMessage: '抽奖规则',
                      })}
                    </span>
                    <div className='icon' />
                  </div>
                  <div className='draw-rule-container'>
                    <div>
                      【{intl.formatMessage({ id: 'caeba54e1d9043ab972684a2b1aa29ec', defaultMessage: '抽奖规则' })}】
                    </div>
                    <div>
                      1.
                      {joinType === 'login'
                        ? intl.formatMessage({
                            id: 'be8bbf7941f74132a1d26231d24f0a48',
                            defaultMessage: '登录后即可获得抽奖机会。',
                          })
                        : intl.formatMessage({
                            id: '4a250a18b31b44bba2def46a21590f2b',
                            defaultMessage: '订阅后即可获得抽奖机会。',
                          })}
                    </div>
                    <div>
                      2.
                      {ticketsType === 'dayN'
                        ? intl.formatMessage(
                            { id: 'c7fd1e26141b49ed9f6624d1eb9ef1e5', defaultMessage: '每天可抽奖{num}次' },
                            { num: baseTickets },
                          )
                        : intl.formatMessage(
                            { id: '7695c2b453da444990ca930611e00de3', defaultMessage: '每人可抽奖{num}次' },
                            { num: baseTickets },
                          )}
                    </div>
                    {
                      shareTickets > 0 &&
                      <div>
                        3.
                        {intl.formatMessage(
                          {
                            id: 'bc44d4f2c52a46298c9ad9e4fd9af66c',
                            defaultMessage: '你通过分享，好友打开链接可获得额外1次机会，每天最多可获得{num}次。',
                          },
                          { num: shareTickets },
                        )}
                      </div>
                    }
                    <br />
                    <div>
                      【{intl.formatMessage({ id: '3fd0bad9e021437caf6cda7ce94aaed9', defaultMessage: '活动时间' })}】
                    </div>
                    <div>
                      {
                        activitySetting?.endAt ?
                          `${formateTimeByTimeZone(activitySetting?.startAt, 'YYYY-MM-DD HH:mm')} - ${formateTimeByTimeZone(activitySetting?.endAt, 'YYYY-MM-DD HH:mm')}` :
                          intl.formatMessage({ id: 'long.time.effective', defaultMessage: '长期有效' })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 显示中奖记录弹窗 */}
          <Dialog
            className='dialog record-modal'
            visible={showRecordModal}
            // visible={true}
            onClose={() => {
              setShowRecordModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: '4093ee61e8934dba85ed855d5a6da017',
              defaultMessage: '中奖记录',
            })}
          >
            {!activityRecord || activityRecord.length === 0 ? (
              <>
                <Image
                  alt='background'
                  className='empty-img'
                  src='https://b.yzcdn.cn/path/to/cdn/dir/empty-box.png'
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                />
                <div className='empty-text'>
                  {intl.formatMessage({ id: 'ef6eb93d0a6e4fd9bb3bf378665b8ae2', defaultMessage: '暂无中奖奖品' })}~
                </div>
              </>
            ) : (
              activityRecord.map((record, index) => {
                return (
                  <Coupons
                    className='coupon'
                    key={index}
                    {...record}
                    onClick={() => {
                      unstable_batchedUpdates(() => {
                        setShowRecordModal(false);
                        setShowCouponsModal(true);
                        setCouponsModalItem(record);
                      });
                    }}
                  />
                );
              })
            )}
          </Dialog>
          {/* 显示中奖弹窗 */}
          <Dialog
            className='dialog draw-modal'
            visible={showDrawModal}
            onClose={() => {
              setShowDrawModal(false);
            }}
            maskClosable={false}
            footer={null}
          >
            <div className='outside-title'>
              {intl.formatMessage({ id: '1186505f118d4b3b805966ab8b560c7a', defaultMessage: '恭喜中奖' })}
            </div>
            <Coupons className='coupon' {...drawResult} />
            <NewButton
              className='btn'
              type='primary'
              size='large'
              onClick={() => {
                setShowDrawModal(false);
                setShowCouponsModal(true);
                setCouponsModalItem(drawResult);
              }}
            >
              {intl.formatMessage({
                id: 'd779e0682db94c74adcec5db1c06dd19',
                defaultMessage: '查看奖品',
              })}
            </NewButton>
          </Dialog>
          {/* 查看卡券弹窗 */}
          <CouponsModal
            className='dialog coupons-modal'
            isOpened={showCouponsModal}
            {...couponsModalItem}
            history={props.history}
            onCancel={() => {
              setShowCouponsModal(false);
            }}
          />
          {/* 显示未中奖弹窗 */}
          <Dialog
            className='dialog white thanks-modal'
            visible={showThanksModal}
            onClose={() => {
              setShowThanksModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: '6fcbe7ec14524be794f1b632eb57ffc1',
              defaultMessage: '谢谢参与',
            })}
          >
            <div className='sub-title'>
              {intl.formatMessage({
                id: drawResultIntlObj?.intlId,
                defaultMessage: drawResultIntlObj?.defaultMessage,
              })}
            </div>
            <Image
              alt='background'
              className='img'
              src='https://b.yzcdn.cn/path/to/cdn/dir/empty-box.png'
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />

            {getThanksModalBtn()}
            <div className='rest-activity-count'>
              {intl.formatMessage({ id: 'abd2299c04c6478e970e6d8182f7f2c5', defaultMessage: '抽奖剩余次数' })}{' '}
              <span className='rest-activity-count_num'>{activityTickets}</span>
            </div>
          </Dialog>
          {/* 订阅抽奖弹窗 */}
          <Dialog
            className='dialog white  email-modal'
            visible={showEmailModal}
            onClose={() => {
              setShowEmailModal(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: 'a26e7dd4c25a4a6e964e49cfa0d9000e',
              defaultMessage: '订阅抽奖',
            })}
          >
            <div className='label'>
              {intl.formatMessage({
                id: '785a183a66954f2f9a0a72d1deaf7b29',
                defaultMessage: '订阅邮箱',
              })}
            </div>
            <Input
              className='email-input-box'
              value={customerNo}
              placeholder={intl.formatMessage({
                id: '3b1044df604f41d9b0f27eb6bac387d8',
                defaultMessage: '请输入您的邮箱号',
              })}
              onChange={handleEmailChange}
            />
            {errorText.customerNo && <FormError>{errorText.customerNo}</FormError>}
            <NewButton
              className='btn'
              type='primary'
              size='large'
              onClick={() => {
                bindEmail(() => {
                  setShowEmailModal(false);
                  setIsBindEmailSuccess(true);
                  setIsStrictDrawing(true);
                });
              }}
            >
              {intl.formatMessage({
                id: 'f68ee633d45c4dc7a52e30a1fd0cba2a',
                defaultMessage: '立即抽奖',
              })}
            </NewButton>
          </Dialog>
          {/* 分享弹窗 */}
          <Dialog
            className='dialog share-modal'
            visible={showShared}
            onClose={() => {
              setShowShared(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: '0b838d37cd1a4b8baa709185df035bf8',
              defaultMessage: '分享给好友',
            })}
          >
            {sharedCount !== undefined && shareTickets - sharedCount > 0 && (
              <div className='sub-title'>
                {intl.formatMessage(
                  { id: '44d915dfb712461993e58460407894d9', defaultMessage: '分享给好友，每天最多可获得{num}次' },
                  { num: shareTickets },
                )}
              </div>
            )}
            <ShareButtons
              showWeChat={false}
              shareThirdUrl={`activity/lucky-draw?activityId=${activityId}&channel=activity_share&sharerId=${customerId}&customerNo=${customerNo}`}
              copyUrl={shareLinkUrl}
              customClass='lunk-draw-share-btns'
              socialShareData={{}}
              handleBuriedPoint={method => {
                buriedPoint('share', { method });
              }}
            />
          </Dialog>

          {/* 无抽奖次数，但还有额外分享次数 */}
          <Dialog
            className='dialog draw-count-none-modal'
            visible={showDrawCountNone}
            onClose={() => {
              setShowDrawCountNone(false);
            }}
            maskClosable={false}
            footer={null}
            title={intl.formatMessage({
              id: 'd3de5b73f3604c599dc4ccec1cbc4b28',
              defaultMessage: '抽奖次数已用完',
            })}
          >
            {sharedCount !== undefined && shareTickets - sharedCount > 0 && (
              <div className='sub-title'>
                {intl.formatMessage({ id: 'f472d51de4744a678e531f142be74db8', defaultMessage: '分享可获取更多次数' })}
              </div>
            )}
            <Image
              alt='background'
              className='img'
              src='https://intl-image.yzcdn.cn/images/public_files/86d384417f958c179a6430c01b7253b9.png'
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />
            <NewButton
              className='btn'
              type='primary'
              size='large'
              onClick={() => {
                setShowThanksModal(false);
                if (sharedCount !== undefined && shareTickets - sharedCount > 0) {
                  setShowDrawCountNone(false);
                  setShowShared(true);
                } else {
                  window.location.href = '/';
                }
              }}
            >
              {sharedCount !== undefined && shareTickets - sharedCount > 0
                ? intl.formatMessage({
                    id: 'e07b375054774168aacd6373840bb952',
                    defaultMessage: '分享赚次数',
                  })
                : intl.formatMessage({
                    id: '5548e678bc9542e7801aca9a92aeb4b1',
                    defaultMessage: '进入店铺',
                  })}
            </NewButton>
            {sharedCount !== undefined && shareTickets - sharedCount <= 0 && (
              <div className='draw-result' onClick={viewDrawRecord}>
                {intl.formatMessage({ id: '4093ee61e8934dba85ed855d5a6da017', defaultMessage: '中奖记录' })}
                <Icon className='iconic-xiala' name='iconic-xiala' size={16} />
              </div>
            )}
          </Dialog>
        </div>
      </div>
    </Style>
  );
};

export default LuckyDraw;
