import { useState } from 'react';
import StoreApi from 'api/store';

export const useBindEmail = ({ intl }) => {
  const [customerNo, setCustomerNo] = useState<string>('');
  const [errorText, setErrorText] = useState({});
  const bindEmail = async (callBack) => {
    if (!customerNo) {
      setErrorText({
        customerNo: intl.formatMessage({
          id: 'please_input_your_email',
          defaultMessage: '请输入邮箱',
        }),
      });
    } else if (customerNo.indexOf('@') === -1) {
      setErrorText({
        customerNo: intl.formatMessage({
          id: 'f379bf130e4f40dc86b924634e2c7c85',
          defaultMessage: '请输入正确电子邮件地址',
        }),
      });
    } else {
      const params = {
        email: customerNo,
        acceptsMarketing: 1, // 订阅状态
      };
      const { customerId } = await StoreApi.subscribe(params);
      callBack && callBack(customerNo, customerId);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event?.target;

    setCustomerNo(value);
  };

  return {
    customerNo,
    errorText,
    handleEmailChange,
    bindEmail,
  };
};
