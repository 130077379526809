/** 待开始：waiting 进行中：scheduled 已结束（到期）：expired */
export enum ActivityStatus {
  Waiting = 'waiting',
  Scheduled = 'scheduled',
  Expired = 'expired',
}
// 中奖结果 success 中奖 thanks 谢谢参与 thanks_not_enough 谢谢参与（库存不足） thanks_disabled 谢谢参与（奖品无效）
export enum DrawResult {
  Success = 'success',
  Thanks = 'thanks',
  ThanksNotEnough = 'thanks_not_enough',
  ThanksDisabled = 'thanks_disabled',
}
export const DrawResultIntl = {
  [DrawResult.Success]: {
    intlId: '74b1a0bbc1184f3cbdebad1c40f16b1f',
    defaultMessage: '领取成功',
  },
  [DrawResult.Thanks]: {
    intlId: 'c1a68f6faa174fac92194a0ddfc47fbd',
    defaultMessage: '很遗憾没有抽中',
  },
  [DrawResult.ThanksNotEnough]: {
    intlId: '4ff0704e469a45cbb10ee44c25bb5c79',
    defaultMessage: '奖品已抢完',
  },
  [DrawResult.ThanksDisabled]: {
    intlId: '10695738da0a4a83a3a97f38dfccd993',
    defaultMessage: '抱歉，奖品已过期',
  },
};
// 奖品类型 折扣码：cardCode 商品：goods 积分：points 谢谢参与：thanks
export enum GiftType {
  CardCode = 'cardCode',
  Goods = 'goods',
  Points = 'points',
  Thanks = 'thanks',
}
export enum GiftStatus {
  Unused = 'unused ', // 待使用
  Expired = 'expired', // 已过期
  Used = 'used', // 已使用
}
