import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
    
    .dialog {
        width: 460px !important;
        min-width: auto !important;
        background: linear-gradient(180deg, #FBF2E3 0%, #FFF 42.51%), #FFF !important;
        padding: 40px !important;
        border-radius: calc(var(--border_radius_card) * 1px) !important;
        position: relative;
        /* &:before{
            content: '';
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 90px;
            background: url(https://b.yzcdn.cn/path/to/cdn/dir/coin-two-bg.png) no-repeat 0 0;
            background-size: contain;
        } */
        
        .zent-dialog-r-title{
            font-weight: 500 !important;
            font-size: 20px !important;
            line-height: 28px !important;
            text-align: center;
            letter-spacing: 0.5px;
            padding: 0 !important;
            border-bottom: none !important;
            color: var(---color_text_field_text, #333) !important;
            .zent-dialog-r-title-text {
              color: #333333;
            }
        }
        .zent-dialog-r-wrap .zent-dialog-r-title .zent-dialog-r-title-text {
          color: #333333;
        }
        .sub-title{
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.5px;
            color: rgba(21, 22, 27, 0.6);
            margin-top:8px;
        }
        .zent-dialog-r-close{
            background: transparent !important;
            color: rgba(0, 0, 0, 1);
            top: 24px !important;
            right: 24px !important;
            font-size: 24px !important;
        }
        .zent-dialog-r-body{
            text-align:center;
            padding:0 !important;
        }
        .lunk-draw-share-btns {
          margin-top: 32px;
          .circular-icon-item {
            width: 40px;
            height: 40px;
          }
        }
        .btn {
          width: 100%;
        }
        .button.large{
            width: 100%;
            padding: 14px !important;
            background: linear-gradient(315deg, #FF5C00 0%, #FF640D 100%);
            box-shadow: 0px 10px 20px rgba(226, 40, 0, 0.2), inset 3px 4px 20px rgba(255, 255, 255, 0.3), inset -3px -3px 15px rgba(255, 255, 255, 0.3), inset -14px -11px 32px #FFAE34;
            border-radius: 50px !important;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5px;
            color: #FFFFFE !important;
            margin-top: 16px;
            box-sizing: border-box;
            cursor: pointer;
        }
        &.dialog.white{
            &:before{
                content:none;
            }
            .zent-dialog-r-title{
                color: #333333;
            }
            .zent-dialog-r-close{
                color: #1A1A1B;
            }
        }
        &.draw-modal{
            background: linear-gradient(180deg, #FFEBC4 0%, #FFFDF7 100%) !important;
            padding: 90px 16px 20px !important;
            .outside-title {
                width: 100%;
                text-align: center;
                top: -77px;
                left: 0;
                position: absolute;
                font-size: 32px;
                font-weight: 600;
                line-height: 45px;
                background: linear-gradient(165deg, #FFEEB1 27.35%, #FFF 80.5%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                // color: #ffffff;
            }
            &::before {
                content: '';
                width: 552px;
                height: 469px;
                background: url(https://intl-image.yzcdn.cn/images/public_files/5a97a3c85ff09c3e05d36e516ee0d3df.png);
                background-size: contain;
                position: absolute;
                left: 50%;
                top: -50%;
                transform: translate(-50%, -5px);
                z-index: -1;
                pointer-events: none;
            }
            &::after {
                content: '';
                width: 114px;
                height: 96px;
                background: url(https://intl-image.yzcdn.cn/images/public_files/7e1f5302aa1708dc9e9dc4c3f435e1f5.png);
                background-size: contain;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(calc(-50% + 17px), calc(-50% + 20px));
                transform-origin: center;
                z-index: 3;
            }
            .coupon {
              margin: 0 24px;
              .container {
                &::after {
                  background: #fff6e5 !important;
                }
              }
            }
            .button {
              margin-top: 24px;
            }
        }
        &.thanks-modal {
            .img {
                width: 240px;
                height: 120px;
                margin: 24px 0 0;
            }
            .rest-activity-count {
                margin-top: 16px;
                color: rgba(21, 22, 27, 0.60);
                text-align: center;
                font-size: 14px;
                &_num {
                    color: #FF4920;
                }
            }
        }
        &.record-modal{
          background: #FFEECB !important;
          padding: 20px 16px !important;
          .zent-dialog-r-title-text {
            color: #9C6B01 !important;
          }
          .zent-dialog-r-close {
            top: 12px !important;
            right: 12px !important;
            color: rgba(0, 0, 0, 1);
          }
          .empty-img {
            width: 240px;
            height: 120px;
            margin-top: 60px;
          }
          .empty-text {
            color: rgba(21, 22, 27, 0.60);
            font-size: 14px;
            line-height: 20px;
            margin: 16px 0 60px;
          }
          .zent-dialog-r-body{
              margin-top: 16px;
              height: 400px;
              overflow: auto;
              ::-webkit-scrollbar  
              {  
                  width: 4px;  /*滚动条宽度*/
                  //background-color: unset;
                  
              } 
              ::-webkit-scrollbar-thumb  
              {  
                  border-radius: 9px;  /*滚动条的圆角*/
                  background-color: rgba(255, 255, 255, 0.4);;  /*滚动条的背景颜色*/
              }  
              .coupon{
                  margin:16px 24px 0;
                  &:first-child{
                      margin-top:0;
                  }
              }
          }

        }
        &.email-modal {
            .label{
                text-align:left;
                margin:32px 0 8px 0;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #474747;
            }
            .zent-form-error{
                text-align:left;
            }
            .email-input-box {  
                height:52px;
                border: 1px solid #D6D6D6 !important;
                background: rgba(255, 255, 255, 0.001) ;
                .zent-input{
                    padding:0 16px;
                }
                .zent-input::-webkit-input-placeholder {
                    color: #999;
                }
                .zent-input::-moz-placeholder {
                    color: #999;
                }
                .zent-input:-ms-input-placeholder {
                    color: #999;
                }
                .zent-input,
                input {
                    text-align: left;
                    color: #15161b !important;
                    box-shadow: none !important;
                    &::after {
                        background: rgba(21, 22, 27, 0.08);
                    }
                }
                
            }
            .btn{
                margin-top:16px;
            }
            
        }
        &.coupons-modal {
          background: linear-gradient(180deg, #FFEBC4 0%, #FFFDF7 100%) !important;
          padding: 32px 16px !important;
          .zent-dialog-r-close {
            top: 12px !important;
            right: 12px !important;
            color: rgba(0, 0, 0, 1) !important;
          }
          .zent-dialog-r-title-text {
            color: #9C6B01 !important;
            font-size: 24px;
            font-weight: 500;
            line-height: 34px;
          }
          .sub-title {
            font-size: 12px;
            line-height: 18px;
            margin-top: 4px;
          }
          .coupons {
            .container {
              &::after {
                background: #fcebda !important;
              }
            }
          }
        }
        &.draw-count-none-modal {
          .img {
            width: 68px;
            height: 68px;
            margin-top: 24px;
          }
          .draw-result {
              color: rgba(21, 22, 27, 0.60);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;  
              margin-top: 16px;
              .iconic-xiala {
                  color: rgba(21, 22, 27, 0.60);
                  margin-left: 4px;
              }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .dialog{
            padding:16px !important;
        }
    }
`;
export default styled.div`
  @keyframes scale {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.9);
    }
  }
  .luck-activity {
    width: 100%;
    position: relative;
    overflow: auto;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    .main {
      /* width: 1440px; */
      position: relative;
      margin: 0 auto;
    }
    .draw-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding-bottom: 50px;
    }
    .bg {
      position: absolute;
      /* width: 1440px; */
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      left: 0;
    }
    .draw-content {
      z-index: 1;
    }
    .coin-bg {
      width: 1200px;
      height: 360px;
      position: absolute;
      top: 0px;
      left: 0px;
      &.lazyload {
        background-color: unset;
        border: none;
      }
    }
    .logo {
      margin-top: 40px;
      height: 32px;
      width: 100%;
      position: relative;
      z-index: 2;
      &.lazyload {
        background-color: unset;
        border: none;
      }
    }
    .title {
      width: 1000px;
      overflow: hidden;
      font-family: Alibaba PuHuiTi;
      font-style: normal;
      font-weight: bold;
      font-size: 80px;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #fffffb;
      margin-bottom: 4px;
      margin-top: 4px;
      text-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
      text-align: center;
      z-index: 1;
    }
    .sub-title {
      width: 1200px;
      overflow: hidden;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: 0.5px;
      color: #fffffb;
      text-align: center;
      z-index: 1;
    }
    .main-bg {
      position: relative;
      width: 1220px;
      height: 460px;
      top: -214px;
      &.lazyload {
        background-color: unset;
        border: none;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: -272px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .section-container {
        margin-left: 24px;
        z-index: 1;
        .section {
          margin-top: 24px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
    .draw-count-down-container {
      width: 100%;
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .draw-count-down-title {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 12px;
        &::before {
          content: '';
          display: block;
          width: 82px;
          height: 1px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        }
        &::after {
          content: '';
          display: block;
          width: 82px;
          height: 1px;
          background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
        }
      }
      .draw-count-down {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #ffffff;
        &_item {
          width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          font-size: 22px;
          font-weight: 600;
          line-height: 22px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0.04) 50%,
            rgba(255, 255, 255, 0.2) 100%
          );
        }
      }
    }
    .luck-draw-container {
      position: relative;

      .luck-draw {
        position: relative;

        width: 458px;
        height: 540px;
        margin: 0 auto;
        background: linear-gradient(90.2deg, #ffca62 0.33%, #ffa800 99.97%);
        box-shadow: 0px 40px 160px rgba(0, 0, 0, 0.3);
        border-radius: 16px;
        .diejia {
          position: absolute;
          width: 458px;
          height: 534px;
          background: linear-gradient(142.27deg, rgba(255, 255, 255, 0) 0%, #ffffff 96.88%);
          border-radius: 16px;
          .diejia2 {
            position: absolute;
            width: 458px;
            height: 533px;
            background: linear-gradient(180deg, #f8e182 0%, #f8e182 94.47%);
            border-radius: 16px;
            .turntable {
              height: 432px;
              .list {
                .item {
                  position: absolute;
                  width: 120px;
                  height: 120px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  background: linear-gradient(180deg, #ffffff 0%, #fff9d7 100%);
                  box-shadow: -4.67532px -4.67532px 8.18182px #fff1c4, 4.67532px 4.67532px 18.7013px #f2bd33,
                    inset -4.67532px -4.67532px 9.35065px #ffffff;
                  border-radius: 18.7013px;
                  box-sizing: border-box;
                  &.active {
                    background: linear-gradient(137.8deg, #fff1eb 9.27%, #ffd8b3 90.26%);
                    border-color: #ff6b30;
                    box-shadow: inset 8px 8px 16px #ffffff, inset -8px -8px 24px #ffb46b;
                    &:before {
                      content: '';
                      display: block;
                      width: 100%;
                      height: 100%;
                      border: 5px solid #ff6b30;
                      position: absolute;
                      border-radius: 12px;
                      box-sizing: border-box;
                    }
                    .img {
                      margin-top: 8px;
                    }
                  }
                  &.list-1 {
                    top: 24px;
                    left: 24px;
                  }
                  &.list-2 {
                    top: 24px;
                    left: 168.5px;
                  }
                  &.list-3 {
                    top: 24px;
                    right: 24px;
                  }
                  &.list-4 {
                    top: 160px;
                    right: 24px;
                  }
                  &.list-5 {
                    top: 296px;
                    right: 24px;
                  }
                  &.list-6 {
                    top: 296px;
                    left: 168.5px;
                    z-index: 1;
                  }
                  &.list-7 {
                    top: 296px;
                    left: 24px;
                  }
                  &.list-8 {
                    top: 160px;
                    left: 24px;
                  }
                  .img {
                    width: 68px;
                    height: 74px;
                    margin: 8px 0 7px 0;
                    &.lazyload {
                      background-color: unset;
                      border: none;
                    }
                  }
                  .name {
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #835900;
                  }
                }
              }

              .start-btn {
                width: 160px;
                height: 160px;
                position: absolute;
                top: 140px;
                left: 150px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: url(https://b.yzcdn.cn/path/to/cdn/dir/go-pc2.png!large.webp);
                background-size: contain;
                color: #fff;
                cursor: pointer;
                &:not(.disabled) {
                  animation: scale 0.8s linear infinite alternate;
                }
                .go {
                  font-family: PingFang SC;
                  font-weight: 600;
                  font-size: 42px;
                  line-height: 43px;
                }
                .arrow {
                  margin-top: 2px;
                  width: 43px;
                  height: 14px;
                  background: url(https://b.yzcdn.cn/path/to/cdn/dir/arrow.png) no-repeat;
                  background-size: contain;
                }
                &.disabled {
                  background: url(https://b.yzcdn.cn/path/to/cdn/dir/go-pc-disabled2.png!large.webp);
                  background-size: contain;
                  cursor: unset;
                  .wait-start {
                    width: 86px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 39px;
                    margin-bottom: 8px;
                  }
                  .time {
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 28px;
                  }
                  .expired {
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 45px;
                  }
                }
              }
            }
            .draw-num {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              .text {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                letter-spacing: 0.5px;
                color: #9c6b01;
              }
              .num {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                padding: 0 8px;
                margin-left: 4px;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 10px 10px 10px 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;

                text-align: center;
                letter-spacing: 0.5px;

                color: #ff6b30;
              }
            }
            .record-and-share {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin: 12px 24px 0;
              .share-btn {
                height: 48px;
                flex: 1;
                margin-right: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                border-radius: 12px;
                background: linear-gradient(92deg, #fff 0%, #fff9d7 100%);
                box-shadow: -4px -4px 7px 0px #fff1c3, -4px -4px 8px 0px #fff inset, 4px 4px 16px 0px #f2bd33;
                color: #9c6b01;
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                position: relative;
                cursor: pointer;
                .iconchoujiangfenxiang {
                  fill: #9c6b01;
                  width: 24px;
                  height: 21px;
                }
                .share-btn-tip {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 600;
                  color: #ffffff;
                  height: 22px;
                  box-sizing: border-box;
                  padding: 4px 6px;
                  position: absolute;
                  right: 0;
                  top: -11px;
                  border-radius: 50px 50px 50px 0px;
                  background: linear-gradient(147deg, #ff863a 8.68%, #ff324d 90.88%), rgba(0, 0, 0, 0.5);
                  animation: scale 0.8s linear infinite alternate;
                }
              }
              .record-btn {
                width: 80px;
                height: 48px;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                letter-spacing: 0.5px;
                color: #9c6b01;
                border-radius: 12px;
                background: linear-gradient(92deg, #fff 0%, #fff9d7 100%);
                box-shadow: -4px -4px 7px 0px #fff1c3, -4px -4px 8px 0px #fff inset, 4px 4px 16px 0px #f2bd33;
                cursor: pointer;
                .iconzhongjiangjilu {
                  fill: #9c6b01;
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
        .share-popover {
          position: absolute;
          z-index: 9;
          bottom: -66px;
          right: 0;
          padding: 24px;
          background: #fff;
          box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.2);
          border-radius: 12px;
          .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.5px;
            color: #333333;
          }
          .share-buttons-container {
            margin-top: 16px !important;
            display: flex;
            align-items: center;
          }
          .circular-icon-item:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .section {
      box-sizing: border-box;
      width: 408px;
      height: 258px;
      padding: 24px;
      margin: 0 auto;
      overflow-y: auto;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 4px 4px 16px rgba(0, 0, 0, 0.15);
      border-radius: 12px;

      .section-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        .icon {
          width: 16px;
          height: 16px;
          background-image: url(https://b.yzcdn.cn/path/to/cdn/dir/circle.png);
          background-size: contain;
        }
        .text {
          margin: 0 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          color: #fdffff;
        }
      }
      .section-content {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #fdffff;

        .item {
          display: flex;
          flex-direction: row;
          margin-top: 16px;
          &:first-child {
            margin-top: 0;
          }
          .name {
            width: 100px;
            margin-right: 24px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .draw-name {
            flex: 1;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
          }
        }
      }

      .container {
        max-height: 198px;
        overflow: hidden;
        position: relative;
        @keyframes scroll {
          from {
            transform: translate(0, 0);
          }
          to {
            transform: translate(0, calc(-100% + 200px));
          }
        }
        .section-content {
          // js控制
          //animation: scroll 3s linear infinite;
        }
      }

      .draw-rule-container {
        color: rgba(253, 255, 255, 1);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .main {
      width: 100% !important;
      overflow: hidden !important;
      .coin-bg {
        width: 100vw;
        height: calc(195 / 480 * 100vw);
      }
      .logo {
        height: 23.3px;
        top: 14px;
      }
      .title {
        margin: 36px 0 2px 0;
        line-height: 1.2;
      }
      .sub-title {
        line-height: 1.2;
      }
      .main-bg {
        width: 100vw;
        height: calc(725 / 1440 * 100vw);
        top: calc(-310 / 1440 * 100vw);
      }
      .row {
        flex-direction: column;
        margin-top: calc(-380 / 1440 * 100vw);
        .luck-draw {
          width: 343px;
          height: 455px;
          .diejia {
            width: 343px;
            height: 439px;
            .diejia2 {
              width: 343px;
              height: 438px;
            }
            .turntable {
              height: 339px !important;
              .item {
                width: 95px !important;
                height: 95px !important;
                &.active {
                  border-width: 3px !important;
                  .img {
                    margin-top: 9px !important;
                  }
                }
                .img {
                  width: 48px !important;
                  height: 48px !important;
                  margin: 12px 0 8px 0 !important;
                }
                .name {
                  font-style: normal !important;
                  font-weight: 500 !important;
                  font-size: 12px !important;
                  line-height: 18px !important;
                }
                &.list-1 {
                  top: 12px !important;
                  left: 16px !important;
                }
                &.list-2 {
                  top: 12px !important;
                  left: 50% !important;
                  transform: translateX(-50%) !important;
                }
                &.list-3 {
                  top: 12px !important;
                  right: 16px !important;
                }
                &.list-4 {
                  top: 120px !important;
                  right: 16px !important;
                }
                &.list-5 {
                  top: 228px !important;
                  right: 16px !important;
                }
                &.list-6 {
                  top: 228px !important;
                  left: 50% !important;
                  transform: translateX(-50%) !important;
                }
                &.list-7 {
                  top: 228px !important;
                  left: 16px !important;
                }
                &.list-8 {
                  top: 120px !important;
                  left: 16px !important;
                }
              }
              .start-btn {
                width: 121px !important;
                height: 121px !important;
                top: 107px !important;
                left: 111px !important;
                cursor: pointer;
                .go {
                  font-size: 32px !important;
                  line-height: 32px !important;
                }
                .arrow {
                  margin-top: 5px !important;
                  width: 32px !important;
                  height: 11px !important;
                }
                &.disabled {
                  .wait-start {
                    width: 86px !important;
                    text-align: center !important;
                    font-weight: 600 !important;
                    font-size: 16px !important;
                    line-height: 1.2 !important;
                    margin-bottom: 4px !important;
                  }
                  .time {
                    font-weight: normal !important;
                    font-size: 10px !important;
                    line-height: 14px !important;
                  }
                  .expired {
                    font-weight: 600 !important;
                    font-size: 18px !important;
                    line-height: 24px !important;
                  }
                }
              }
            }
            .draw-num {
              .text {
                font-weight: 600 !important;
                font-size: 16px !important;
                line-height: 24px !important;
              }
              .num {
                height: 16px !important;
                padding: 0 4px !important;
                margin-left: 4px !important;
                font-size: 14px !important;
                line-height: 24px !important;
              }
            }
            .record-and-share {
              .record-btn {
                height: 44px !important;
                margin: 0 !important;
                font-size: 16px !important;
                font-weight: 600 !important;
                line-height: 24px !important;
              }
              .share-btn {
                height: 44px !important;
              }
            }
          }
        }
        .section-container {
          margin: 0 !important;
        }
        .section {
          margin-top: 24px !important;
          width: 343px !important;
          padding: 20px !important;
          .text {
            font-size: 16px !important;
            line-height: 18px !important;
          }
          .section-content {
            font-size: 13px !important;
            font-weight: lighter !important;
            line-height: 18px !important;
          }
        }
      }
    }
  }
`;
