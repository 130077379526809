import { useState, useMemo, useEffect, useRef } from 'react';
import ActivityApi from 'api/activity';
import qs from 'querystring';
import LocalStorage from 'utils/local-storage';
import { generateRandomString } from 'utils/web-tool';
import { Notify } from 'zent';

const JOIN_TYPE = {
  login: 'login',
};

export const useDrawShare = (props) => {
  const {
    intl,
    activityId,
    customerId = '',
    customerNo = '',
    joinType,
    userInfo,
    onSharedFinishedCallBack,
  } = props;

  const sharedTickets = useRef(0);
  const shareLinkUrl = useMemo(() => {
    return (!__isServer ? window.location.origin : '') + `/activity/lucky-draw?activityId=${activityId}&channel=activity_share&sharerId=${customerId}&customerNo=${customerNo}`;
  }, [activityId, customerId, customerNo]);
  const triggerShareTimer = useRef<any>(null);


  const query = !__isServer ? qs.parse(location?.search?.split?.('?').pop() || '') : {};

  const onTriggerShare = () => {
    if ((joinType === JOIN_TYPE.login && customerId) || (joinType !== JOIN_TYPE.login && customerNo)) {
      clearInterval(triggerShareTimer.current);
      triggerShareTimer.current = setInterval(() => {
        onSharedFinishedCallBack().then(res => {
          if (res.sharedTickets > sharedTickets.current) {
            sharedTickets.current = res.sharedTickets;
            Notify.success(
              intl.formatMessage(
                {
                  id: '2dc6c67704d947bfbc59b9a9e498587e',
                  defaultMessage: '分享成功，获取{num}次抽奖次数',
                },
                {
                  num: res.sharedTickets - sharedTickets.current,
                },
              ),
            );
          }
        })
      }, 5000);
    }
  };

  // 分享逻辑
  useEffect(() => {
    if (!(query.activityId && (query.sharerId || query.customerNo))) return;
    if (
      (query.sharerId && (query.sharerId == userInfo?.customerId)) ||
      (query.customerNo && (query.customerNo == userInfo?.customerNo))
    ) return; // 分享者和点击者为同一个人不处理
    const storageActivityClickerId = LocalStorage.get(`${query.activityId}-shareActivityClickerId`);
    if (!userInfo?.customerId && storageActivityClickerId) return; // 未登录的场景下有被分享过, 不处理

    let clickerId = userInfo?.customerId;
    if (!clickerId) { // H5 未登录, 生成一个随机串
      clickerId = generateRandomString(10);
      LocalStorage.set(`${query.activityId}-shareActivityClickerId`, clickerId);
    }
    ActivityApi.addDrawActivityTickets({
      activityId,
      channel: 'activity_share',
      accountNo: query.customerNo,
      customerId: query.sharerId ? Number(query.sharerId) : null,
      clickerId: String(clickerId),
    })
  }, []);

  return {
    shareLinkUrl,
    onTriggerShare,
  };
};
