import React from 'react';
import Style from './style';
import cn from 'classnames';
import { formateTimeByTimeZone } from 'utils/datetime';
import { GiftStatus } from 'const/activity';
import { useIntl } from 'react-intl';
import Image from 'components/base/image';
import NewButton from 'components/base/new-button';
const Coupons = (props) => {
  const intl = useIntl();
  const { itemImageUrl, itemName, startAt, endAt, status, onClick } = props;
  const disabledUse = status === GiftStatus.Used || status === GiftStatus.Expired;
  return (
    <Style className={cn(props.className)}>
      <div className={cn('coupons')}>
        <div className='container'>
          <div className='left'>
            <Image
              alt='background'
              className='img'
              src={itemImageUrl}
              style={{ objectFit: 'contain', objectPosition: 'center' }}
            />
          </div>
          <div className='midden'>
            <div className='coupon-title'>{itemName}</div>
            <div className='coupon-time'>
              {formateTimeByTimeZone(startAt, 'YYYY.MM.DD')}-{formateTimeByTimeZone(endAt, 'YYYY.MM.DD')}
            </div>
          </div>
          {status && (
            <div className='right'>
              <NewButton
                className={cn('btn', {
                  disabled: disabledUse,
                })}
                type='primary'
                size='small'
                onClick={() => onClick && !disabledUse && onClick()}
              >
                {status === GiftStatus.Used
                  ? intl.formatMessage({
                      id: 'coupons.used',
                      defaultMessage: '已使用',
                    })
                  : status === GiftStatus.Expired
                  ? intl.formatMessage({
                      id: 'coupons.overtime',
                      defaultMessage: '已过期',
                    })
                  : intl.formatMessage({
                      id: '2b0c8c8d641f4d0297fd46811dffd506',
                      defaultMessage: '去查看',
                    })}
              </NewButton>
            </div>
          )}
        </div>
      </div>
    </Style>
  );
};
export default Coupons;
