/**
 * 活动抽奖相关的api接口封装
 */
import BaseApi from './base';

class ActivityApi extends BaseApi {
  // 获取活动设置
  getDrawActivitySetting(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/getDrawActivitySetting',
      params,
    );
  }
  // 获取抽奖次数
  getDrawActivityTickets(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/getDrawActivityTickets',
      params,
    );
  }
  // 获取中奖名单
  getBulkActivityNameList(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/bulkGetActivityNameList',
      params,
    );
  }
  // 获取中奖记录
  getDrawActivityRecords(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/getDrawActivityRecords',
      params,
    );
  }
  // 获取抽奖物品列表
  getDrawActivityItems(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/getDrawActivityItems',
      params,
    );
  }
  // 参加抽奖
  joinDrawActivity(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/joinDrawActivity',
      params,
    );
  }
  // 添加抽奖次数
  addDrawActivityTickets(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/addDrawActivityTickets',
      params,
    );
  }
  // 新获取抽奖次数
  getDrawTickets(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.ump.draw.controller.web.DrawActivityController/getDrawTickets',
      params,
    );
  }
}

export default new ActivityApi();
